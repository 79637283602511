<template>
	<div class="medicalStaffData">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="省：">
					<el-select
						v-model="formInline.economize"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 2"
						placeholder="请选择省"
						@change="changeEconomize($event, 1)"
					>
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：">
					<el-select
						v-model="formInline.market"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 4"
						placeholder="请选择市"
						@change="changeEconomize($event, 2)"
					>
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select
						v-model="formInline.district"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 6"
						placeholder="请选择"
						@change="changeEconomize($event, 3)"
					>
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="乡镇：">
					<el-select v-model="formInline.townCode" :disabled="userInfo.regionCode && userInfo.regionCode.length >= 9" placeholder="请选择">
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<!-- 
				<el-form-item prop="villageCode" label="村：">
					<el-select v-model="formInline.villageCode" placeholder="请选择" >
						<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="getData" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="container">
			<div class="medicalStaffData-title">
				<div class="medicalStaffData-title-content clearfix">
					<div class="institutionTotal float-l">
						<img src="@/assets/imgs/institution.png" alt="" />
						<div class="institutionTotal-content">
							<p>机构数</p>
							<p>{{ datas.institutionCount || 0 }}</p>
						</div>
					</div>
					<div class="institutionTotal float-l mr0">
						<img src="@/assets/imgs/peoples.png" alt="" />
						<div class="institutionTotal-content">
							<p>医护人员数</p>
							<p>{{ datas.medicalWorkerCount || 0 }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="content">
				<el-row :gutter="12">
					<el-col :span="12">
						<div class="chartsClass" ref="medicalStaffHeight">
							<p>
								<img src="@/assets/imgs/datas.png" alt="" />
								医护人员分类及人数统计
							</p>
							<div
								class="nullData"
								:style="{ width: '100%', height: medicalStaffHeight + 'px', lineHeight: medicalStaffHeight + 'px' }"
								v-if="datas.medicalWorkerTypeTotal == 0"
							>
								暂无数据
							</div>
							<div id="medicalStaff" :style="{ width: '100%', height: medicalStaffHeight + 'px' }" v-show="datas.medicalWorkerTypeTotal !== 0"></div>
						</div>
						<div class="chartsClass" ref="titleChartsHeight">
							<p>
								<img src="@/assets/imgs/datas.png" alt="" />
								职称分类及人数统计
							</p>
							<div
								class="nullData"
								:style="{ width: '100%', height: titleChartsHeight + 'px', lineHeight: titleChartsHeight + 'px' }"
								v-if="datas.titleTypeTotal == 0"
							>
								暂无数据
							</div>
							<div id="titleCharts" :style="{ width: '100%', height: titleChartsHeight + 'px' }" v-show="datas.titleTypeTotal !== 0"></div>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="chartsClass" ref="postChartsHeight">
							<p style="margin-bottom: 12px">
								<img src="@/assets/imgs/datas.png" alt="" />
								岗位分类及人数统计
							</p>
							<div
								class="nullData"
								:style="{ width: '100%', height: postChartsHeight + 'px', lineHeight: postChartsHeight + 'px' }"
								v-if="datas.postTypeTotal == 0"
							>
								暂无数据
							</div>
							<div id="postCharts" :style="{ width: '100%', height: postChartsHeight + 'px' }" v-show="datas.postTypeTotal !== 0"></div>
						</div>
						<div class="chartsClass" ref="hospitalChartsHeight">
							<p style="margin-bottom: 12px">
								<img src="@/assets/imgs/datas.png" alt="" />
								机构分类及人数统计
							</p>
							<div
								class="nullData"
								:style="{ width: '100%', height: hospitalChartsHeight + 'px', lineHeight: hospitalChartsHeight + 'px' }"
								v-if="datas.institutionTypeTotal == 0"
							>
								暂无数据
							</div>
							<div
								id="hospitalCharts"
								:style="{ width: '100%', height: hospitalChartsHeight + 'px' }"
								v-show="datas.institutionTypeTotal !== 0"
							></div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts';
export default {
	name: 'medicalStaffData',
	data() {
		return {
			formInline: {
				economize: null,
				market: null,
				district: null,
				townCode: null,
				// villageCode: null,
			},
			townList: [], //乡镇
			// villageList: [], //村
			districtList: [], //区县
			marketList: [], //市
			economizeList: [], //省
			medicalStaffHeight: 318, // 医护人员分类及人数统计图表高度
			titleChartsHeight: 318, // 职称分类及人数统计图表高度
			postChartsHeight: 328, // 岗位分类及人数统计图表高度
			hospitalChartsHeight: 328, // 机构分类及人数统计图表高度
			datas: {},
			userInfo: {},
		};
	},
	mounted() {
		this.getEconomizeList();
		this.userInfo = JSON.parse(sessionStorage.getItem('userInfo')) || {};
		switch (this.userInfo.regionCode.length) {
			case 2:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				break;
			case 4:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				break;
			case 6:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				break;
			case 9:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				this.formInline.townCode = this.userInfo.chinaRegionDtoList[3].code;
				this.changeEconomize(this.userInfo.regionCode, 4);
				break;
			case 12:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				this.formInline.townCode = this.userInfo.chinaRegionDtoList[3].code;
				this.changeEconomize(this.userInfo.regionCode, 4);
				this.formInline.villageCode = this.userInfo.chinaRegionDtoList[4].code;
				break;
			default:
				break;
		}
		this.$nextTick(() => {
			this.medicalStaffHeight = this.$refs.medicalStaffHeight.offsetHeight - 90 > 318 ? this.$refs.medicalStaffHeight.offsetHeight - 90 : 318;
			this.titleChartsHeight = this.$refs.titleChartsHeight.offsetHeight - 90 > 318 ? this.$refs.titleChartsHeight.offsetHeight - 90 : 318;
			this.postChartsHeight = this.$refs.postChartsHeight.offsetHeight - 90 > 328 ? this.$refs.postChartsHeight.offsetHeight - 90 : 328;
			this.hospitalChartsHeight = this.$refs.hospitalChartsHeight.offsetHeight - 90 > 328 ? this.$refs.hospitalChartsHeight.offsetHeight - 90 : 328;
		});
		this.getData();
	},
	methods: {
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: null,
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			switch (type) {
				case 1:
					this.formInline.market = null;
					this.formInline.distinguish = null;
					this.formInline.townCode = null;
					this.marketList = [];
					this.districtList = [];
					this.townList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.distinguish = null;
					this.formInline.townCode = null;

					this.districtList = [];
					this.townList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					this.formInline.townCode = null;
					this.townList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 获取数据
		getData() {
			const { economize, market, district, townCode } = this.formInline;
			let code =
				economize && !market && !district && !townCode
					? economize
					: economize && market && !district && !townCode
					? market
					: economize && market && district && !townCode
					? district
					: economize && market && district && townCode
					? townCode
					: null;
			this.$http
				.get(this.api['MedicalWorkerAnalysis#index'].href, { params: { regionCode: code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.datas = res.data.collection[0] || {};
						const { medicalWorkerType, postType, titleType, institutionType } = res.data.collection[0];
						let data1 = [],
							data2 = [],
							data3 = [],
							data4 = [];
						if (!medicalWorkerType || JSON.stringify(medicalWorkerType) == '{}') {
							this.datas.medicalWorkerTypeTotal = 0;
						} else {
							for (const key in medicalWorkerType) {
								if (Object.hasOwnProperty.call(medicalWorkerType, key)) {
									const element = medicalWorkerType[key];
									data1.push({
										name: key,
										value: element,
									});
								}
							}
							this.getMedicalStaffData(data1);
						}
						if (!titleType || JSON.stringify(titleType) == '{}') {
							this.datas.titleTypeTotal = 0;
						} else {
							for (const key in titleType) {
								if (Object.hasOwnProperty.call(titleType, key)) {
									const element = titleType[key];
									data2.push({
										name: key,
										value: element,
									});
								}
							}
							this.getTitleChartsData(data2);
						}
						if (!postType || JSON.stringify(postType) == '{}') {
							this.datas.postTypeTotal = 0;
						} else {
							for (const key in postType) {
								if (Object.hasOwnProperty.call(postType, key)) {
									const element = postType[key];
									data3.push({
										name: key,
										value: element,
									});
								}
							}
							this.getPostChartsData(data3);
						}
						if (!institutionType || JSON.stringify(institutionType) == '{}') {
							this.datas.institutionTypeTotal = 0;
						} else {
							for (const key in institutionType) {
								if (Object.hasOwnProperty.call(institutionType, key)) {
									const element = institutionType[key];
									data4.push({
										name: key,
										institutionName: element.size,
										medicalWorker: element.count,
									});
								}
							}
							this.getHospitalChartsData(data4);
						}
					}
				})
				.catch((e) => {});
		},
		// 获取医护人员数据
		getMedicalStaffData(data) {
			let myChart = echarts.init(document.getElementById('medicalStaff'));
			let title = '共计(人数)';
			let formatNumber = function (num) {
				let reg = /(?=(\B)(\d{3})+$)/g;
				return num.toString().replace(reg, ',');
			};
			let total = data.reduce((a, b) => {
				return a + b.value * 1;
			}, 0);
			myChart.setOption({
				title: {
					text: '{name|' + title + '}\n{val|' + formatNumber(total) + '}',
					textStyle: {
						color: '#333333',
						rich: {
							name: {
								fontSize: 12,
								fontWeight: 'normal',
								color: '#666666',
								padding: [10, 0],
								align: 'center',
							},
							val: {
								fontSize: 32,
								fontWeight: 'bold',
								color: '#333333',
								align: 'center',
							},
						},
					},
					textAlign: 'center',
					x: '23.5%',
					y: 'center',
				},
				tooltip: {
					trigger: 'item',
					show: true,
					// formatter: '{b} : {d}',
					// backgroundColor: 'rgba(0,0,0,0.7)', // 背景
					// padding: [8, 10], //内边距
					// extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);', //添加阴影
				},
				color: ['#165DFF', '#04C59F', '#F7BA1E', '#02CADF', '#d91ad9'],
				legend: {
					backgroundColor: '#F9FAFC',
					padding: [25, 32],
					width: 300,
					type: 'plain',
					icon: 'circle',
					orient: 'vertical',
					left: '50%',
					top: 'center',
					align: 'left',
					itemGap: 15,
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					symbolKeepAspect: false,
					textStyle: {
						color: '#333',
						rich: {
							name: {
								verticalAlign: 'right',
								align: 'left',
								width: 120,
								fontSize: 12,
							},
							value: {
								align: 'right',
								width: 60,
								fontSize: 12,
							},
						},
					},
					data: data.map((item) => item.name),
					formatter: function (name) {
						if (data && data.length) {
							for (var i = 0; i < data.length; i++) {
								if (name === data[i].name) {
									return '{name| ' + name + '}' + '{value| ' + data[i].value + '(人)}';
								}
							}
						}
					},
				},
				series: [
					{
						name: '数量',
						type: 'pie',
						radius: ['40%', '52%'],
						center: ['24%', '50%'],
						data: data,
						label: {
							normal: {
								show: false,
								position: 'center',
								formatter: '{text|{c}}\n{b}',
								rich: {
									text: {
										align: 'center',
										verticalAlign: 'middle',
										padding: 8,
										fontSize: 30,
									},
									value: {
										align: 'center',
										verticalAlign: 'middle',
										fontSize: 20,
									},
								},
							},
							emphasis: {
								show: false,
							},
						},
						labelLine: {
							normal: {
								show: false,
							},
						},
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 获取职称数据
		getTitleChartsData(data) {
			let myChart = echarts.init(document.getElementById('titleCharts'));
			let title = '共计(人数)';
			let formatNumber = function (num) {
				let reg = /(?=(\B)(\d{3})+$)/g;
				return num.toString().replace(reg, ',');
			};
			let total = data.reduce((a, b) => {
				return a + b.value * 1;
			}, 0);
			myChart.setOption({
				title: {
					text: '{name|' + title + '}\n{val|' + formatNumber(total) + '}',
					textStyle: {
						color: '#333333',
						rich: {
							name: {
								fontSize: 12,
								fontWeight: 'normal',
								color: '#666666',
								padding: [10, 0],
								align: 'center',
							},
							val: {
								fontSize: 32,
								fontWeight: 'bold',
								color: '#333333',
								align: 'center',
							},
						},
					},
					textAlign: 'center',
					x: '23.5%',
					y: 'center',
				},
				tooltip: {
					trigger: 'item',
					show: true,
					// formatter: '{b} : {d}',
					// backgroundColor: 'rgba(0,0,0,0.7)', // 背景
					// padding: [8, 10], //内边距
					// extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);', //添加阴影
				},
				color: ['#165DFF', '#04C59F', '#F7BA1E', '#02CADF', '#D91AD9', '#3491FA', '#FF7D00'],
				legend: {
					backgroundColor: '#F9FAFC',
					padding: [25, 32],
					width: 300,
					type: 'plain',
					icon: 'circle',
					orient: 'vertical',
					left: '50%',
					top: 'center',
					align: 'left',
					itemGap: 15,
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					symbolKeepAspect: false,
					textStyle: {
						color: '#333',
						rich: {
							name: {
								verticalAlign: 'right',
								align: 'left',
								width: 120,
								fontSize: 12,
							},
							value: {
								align: 'right',
								width: 60,
								fontSize: 12,
							},
						},
					},
					data: data.map((item) => item.name),
					formatter: function (name) {
						if (data && data.length) {
							for (var i = 0; i < data.length; i++) {
								if (name === data[i].name) {
									return '{name| ' + name + '}' + '{value| ' + data[i].value + '(人)}';
								}
							}
						}
					},
				},
				series: [
					{
						name: '数量',
						type: 'pie',
						radius: ['40%', '52%'],
						center: ['24%', '50%'],
						data: data,
						label: {
							normal: {
								show: false,
								position: 'center',
								formatter: '{text|{c}}\n{b}',
								rich: {
									text: {
										align: 'center',
										verticalAlign: 'middle',
										padding: 8,
										fontSize: 30,
									},
									value: {
										align: 'center',
										verticalAlign: 'middle',
										fontSize: 20,
									},
								},
							},
							emphasis: {
								show: false,
							},
						},
						labelLine: {
							normal: {
								show: false,
							},
						},
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 获取岗位数据
		getPostChartsData(data) {
			let myChart = echarts.init(document.getElementById('postCharts'));
			myChart.setOption({
				legend: {
					show: false,
				},
				grid: {
					top: 0,
					left: 32,
					right: 20,
					bottom: 0,
					containLabel: true,
				},
				tooltip: {
					trigger: 'item',
					show: true,
				},
				barWidth: 16,
				xAxis: {
					type: 'value',
					splitLine: {
						lineStyle: {
							color: '#E5E6EB',
							type: 'dashed',
						},
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						//  改变x轴颜色
						lineStyle: {
							color: '#ffffff',
						},
					},
					axisLabel: {
						//  改变x轴字体颜色和大小
						textStyle: {
							color: '#666666',
							fontSize: 12,
							lineHeight: 25,
						},
					},
				},
				yAxis: {
					type: 'category',
					data: data.map((item) => item.name),
					splitLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						//  改变y轴颜色
						lineStyle: {
							color: '#C9CDD4',
						},
					},

					axisLabel: {
						textStyle: {
							color: '#666666',
							fontSize: 12,
						},
					},
				},
				series: [
					{
						type: 'bar',
						name: '岗位数据',
						itemStyle: {
							normal: {
								label: {
									show: false, //开启显示
									position: 'right', //在上方显示
									textStyle: {
										//数值样式
										color: 'rgba(250,250,250,0.6)',
										fontSize: 16,
										fontWeight: 600,
									},
								},
								color: '#1db9b1',
							},
						},
						data: data.map((item) => item.value),
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 获取机构数据
		getHospitalChartsData(data) {
			let myChart = echarts.init(document.getElementById('hospitalCharts'));
			myChart.setOption({
				legend: {
					show: true,
					data: ['机构数量', '医护人员分布数量'],
					top: '5%',
					icon: 'circle',
					textStyle: {
						color: '#333333',
						fontSize: 12,
					},
				},
				tooltip: {
					trigger: 'item',
					show: true,
				},
				grid: {
					top: 46,
					left: 32,
					right: 20,
					bottom: 0,
					containLabel: true,
				},
				xAxis: {
					type: 'value',
					splitLine: {
						lineStyle: {
							color: '#E5E6EB',
							type: 'dashed',
						},
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						//  改变x轴颜色
						lineStyle: {
							color: '#ffffff',
						},
					},
					axisLabel: {
						//  改变x轴字体颜色和大小
						textStyle: {
							color: '#666666',
							fontSize: 12,
							lineHeight: 25,
						},
					},
				},
				yAxis: {
					type: 'category',
					data: data.map((item) => item.name),
					splitLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						//  改变y轴颜色
						lineStyle: {
							color: '#C9CDD4',
						},
					},

					axisLabel: {
						textStyle: {
							color: '#666666',
							fontSize: 12,
						},
					},
				},
				series: [
					{
						type: 'bar',
						name: '机构数量',
						barWidth: 9,
						barGap: 0,
						itemStyle: {
							normal: {
								label: {
									show: false, //开启显示
									position: 'right', //在上方显示
									textStyle: {
										//数值样式
										color: 'rgba(250,250,250,0.6)',
										fontSize: 16,
										fontWeight: 600,
									},
								},
								color: '#165DFF',
							},
						},
						data: data.map((item) => item.institutionName),
					},

					{
						type: 'bar',
						name: '医护人员分布数量',
						barWidth: 9,
						barGap: 0,
						itemStyle: {
							normal: {
								label: {
									show: false, //开启显示
									position: 'right', //在上方显示
									textStyle: {
										//数值样式
										color: 'rgba(250,250,250,0.6)',
										fontSize: 16,
										fontWeight: 600,
									},
								},
								color: '#02CADF',
							},
						},
						data: data.map((item) => item.medicalWorker),
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.medicalStaffData {
	.container {
		margin: 16px;
	}
	.demo-form-inline {
		/deep/ .el-select {
			width: 106px !important;
			.el-input {
				width: 106px !important;
				.el-input__inner {
					width: 106px !important;
				}
			}
		}
		/deep/ .el-input {
			width: 106px;
		}
	}
	&-title {
		padding: 16px;
		background-color: #fff;
		border-radius: 4px;
		margin-bottom: 12px;

		&-content {
			.institutionTotal {
				display: flex;
				align-items: center;
				width: calc(50% - 6px);
				margin-right: 12px;
				background: #f9fafc;
				border-radius: 4px;
				padding: 16px;
				img {
					margin-right: 16px;
				}
				&-content {
					p {
						color: #999;
						font-size: 14px;
						height: 14px;
						line-height: 14px;
						&:last-child {
							font-size: 24px;
							line-height: 24px;
							height: 24px;
							margin-top: 12px;
							color: #333;
						}
					}
				}
			}
		}
	}
	.content {
		.chartsClass {
			background-color: #fff;
			margin-bottom: 12px;
			padding: 20px 16px;
			border-radius: 4px;
			&:last-child {
				margin-bottom: 0;
			}
			p {
				font-size: 16px;
				line-height: 28px;
				font-weight: 500;
				color: #333333;
				margin-bottom: 22px;
				display: flex;
				align-items: center;
				img {
					margin-right: 12px;
				}
			}
		}
	}
	.mr0 {
		margin-right: 0 !important;
	}
	.nullData {
		text-align: center;
		color: #999999;
		font-size: 20px;
	}
}
</style>